import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import SearchBlock from "components/search/SearchBlock";
import { autobind } from "react-decoration";
import { page } from "components/page";
import gql from "graphql-tag";
import GraphqlFragment from "lib/GraphqlFragment";
import DataList from "components/list/DataList";
import QuestionnaireDisplayComp from "components/resource/QuestionnaireDisplayComp";
import QuestionnaireLayout from "components/QuestionnaireLayout";
import DBResourceDetailLightBox from "components/resource/DBResourceDetailLightBox";

import navigate from "lib/navigate";
import qs from "query-string";

const getQuestionnaireList = gql`
  query getQuestionnaireList($searchForm: SearchForm){
    getQuestionnaireList(Input: $searchForm){
      ${GraphqlFragment.defaultLayoutReturn}
    }
  }
  ${GraphqlFragment.defaultLayoutFragment} 
`;

@withI18next(["common"])
@page
class QuestionnaireList extends React.Component {
  constructor(props) {
    super(props);
    let state = this.getQueryState(props, true);
    this.state = state;
  }

  componentWillReceiveProps(props) {
    if (this.props !== props) {
      this.setQueryState(props);
    }
  }

  @autobind
  setQueryState(props) {
    let state = this.getQueryState(props);

    if (JSON.stringify(state) !== JSON.stringify(this.state)) {
      this.setState({
        ...state,
      });
    }
  }

  @autobind
  getQueryState(props, initial) {
    let { search, readerStore } = props;
    let params = { ...search };
    let { pageNo, limit, resourceType } = params;

    let dbID;
    if (!initial) {
      dbID = this.state.dbID;
    }

    dbID = dbID || "";

    pageNo = pageNo || 1;
    //limit = limit || 10;  //前端限制不換頁
    limit = 99999;

    let searchForm = {
      pageNo,
      limit,
      resourceType,
    };

    Object.keys(searchForm).map((key) => {
      if (
        searchForm[key] === null ||
        (typeof searchForm[key] === "string" && searchForm[key].trim() === "")
      ) {
        delete searchForm[key];
      }
    });

    let state = {
      searchForm: searchForm,
      auth: readerStore.auth,
      resourceType: resourceType,
      dbID: dbID,
    };

    return state;
  }

  @autobind
  handleClick(item) {
    this.setState({ dbID: item.id });
    this.detailLightBox.popup();
    window.history.pushState(
      "",
      "",
      "/resourceDetail?resourceType=databases&id=" + item.id
    );
  }

  render() {
    return (
      <>
        <Layout {...this.props}>
          <div className="main sp" id="center">
            <SearchBlock />
            <section className="col-6-6 main_content">
              <div className="container">
                <a
                  className="accesskey"
                  href="javascript:;"
                  id="aC"
                  accesskey="C"
                  title={this.props.t("jumperrwd.common.mainContenctArea")}>
                  :::
                </a>
                <h2>{this.props.t("hyint.common.questionnaire")}</h2>
                <a href="javascript:history.back();" className="back">
                  {this.props.t("hyint.common.table.back")}
                </a>
                <div className="detail_block">
                  <DataList
                    query={getQuestionnaireList}
                    variables={{
                      searchForm: this.state.searchForm,
                    }}
                    fetchPolicy={"network-only"}
                    displayComp={QuestionnaireDisplayComp}
                    pagination={"NormalPagination"}
                    handleClick={this.handleClick}
                    customComponentLayout={QuestionnaireLayout}
                    showPagination={false}
                  />
                </div>
              </div>
            </section>
          </div>
          <DBResourceDetailLightBox
            ref={(c) => {
              this.detailLightBox = c;
            }}
            id={this.state.dbID}
            resourceType="databases"
            {...this.props}
          />
        </Layout>
      </>
    );
  }
}

export default QuestionnaireList;
