import React from "react";

class QuestionnaireLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="table_list">
          <table>
            <thead>
              <tr>
                <th>編號</th>
                <th className="title">問卷標題</th>
                <th>資源名稱</th>
                <th>調查起迄時間</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {this.props.compDataContent}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

QuestionnaireLayout.propTypes = {};

export default QuestionnaireLayout;
